import React, { useState, useEffect } from 'react';
import ReactGA from 'react-ga4';
import ReactPixel from 'react-twitter-pixel';
import { event } from 'react-ga4';
import { BrowserRouter as Router, Routes, Route, Link, useLocation } from 'react-router-dom';
import './App.css';

ReactGA.initialize('G-TGLQNLF28T');

function Home() {
  const [price, setPrice] = useState('');
  const [rate, setRate] = useState('');
  const [shares, setShares] = useState('');
  const [result, setResult] = useState('');

  const calculate = () => {
    const contractValue = shares * (price * 1.02);
    const costPerYear = contractValue * (rate / 100);
    const costPerDay = costPerYear / 360;

    setResult('Daily Charge: $' + costPerDay.toFixed(2));
    
    event({
      action: 'calculate_click', // The action of the event
      params : {
        event_category: 'Button Click', // The category of the event
        event_label: 'Calculate', // The label of the event
      }
    });
    ReactPixel.track('Calculate');
  };

  return (
    <div className="home-content">
      <h1>Stock Loan Interest Calculator</h1>
      <label>
        Stock Price:
        <input type="number" value={price} onChange={e => setPrice(e.target.value)} />
      </label>
      <label>
        Negative Rate (%):
        <input type="number" value={rate} onChange={e => setRate(e.target.value)} />
      </label>
      <label>
        # of Shares:
        <input type="number" value={shares} onChange={e => setShares(e.target.value)} />
      </label>
      <button onClick={calculate}>Calculate</button>
      <p>{result}</p>
    </div>
  );
}

function About() {
  return (
    <div className="about-content">
      <h1>About</h1>
      <p>This calculator is designed to calculate the theoretical daily cost of borrowing stock, given the stock price, the number of shares, and the indicitive borrow rate.</p>
      <p>Here's how it works:</p>
        <div>
          <p><strong>Contract Value</strong> = Number of Shares x (Closing Price x 1.02)</p>
          <p><strong>Annual Cost</strong> = Contract Value x Rebate Rate</p>
          <p><strong>Daily Cost</strong> = Annual Cost / 360</p>
        </div>
      <p>The contract value is calculated by multiplying the number of shares by the stock price. Price is rounded up to 102% for collateral protection. </p>
      <p>The annual cost is then calculated by multiplying the contract value by the rebate rate (expressed as a decimal). This gives the total amount of interest that would be paid over a year.</p>
      <p>Finally, we take the annual cost and divide it by 360. This is the daily charge that the borrower would be required to pay for the given position size. </p>
      <p>Please note that this is simplified and actual stock loan calculations involve additional factors and complexities. The indicitive rate is not always the rate charged. The rate starts to be charged from the settlement date. Borrow rates are based on supply and demand -- rates will vary daily.</p>
    </div>
  );
}

function GAListener() {
  const location = useLocation();

  useEffect(() => {
    ReactGA.send('pageview', location.pathname); // records page view
    ReactPixel.init('ofmnn');
    ReactPixel.pageView(); // For tracking page view
  }, [location]);

  return null;
}

function App() {
  return (
    <Router>
      <GAListener />
      <div>
        <nav className="nav">
          <ul>
            <li>
              <Link to="/">Home</Link>
            </li>
            <li>
              <Link to="/about">About</Link>
            </li>
          </ul>
        </nav>

        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
        </Routes>

        <footer>
          Built by <a href="https://twitter.com/JakeChupick" target="_blank" rel="noopener noreferrer">Jake Chupick</a>
        </footer>
      </div>
    </Router>
  );
}



export default App;

